<template>
  <div class="cartPage">
    <el-container class="normalCon">
      <el-header>
        <div class="header">
          <div class="header_title">
            <span>购物车</span>
          </div>
        </div>
      </el-header>
      <el-main>
        <div class="cartMain">
          <div class="cartHeadTil">
            <el-checkbox v-model="chooseAll" @change="handleCheckAllChange">全部商品</el-checkbox>
          </div>
          <div class="goodsCon">
            <nut-leftslip
              v-for="item in goodsList"
              :key="item.id"
              ref="leftslip"
              v-show="goodsList.length > 0"
            >
              <div slot="slip-main" class="slip-main shopGoodList">
                <div class="chooseVal" @click="clickOn(item)">
                  <el-checkbox v-model="item.choose" @change="handleCheckedCitiesChange(item)"/>
                </div>
                <div class="leftImg">
                  <img v-if="item.imgUrl === null || item.imgUrl === ''" src="../assets/nodata.png" alt="">
                  <img v-else :src="item.imgUrl" alt="">
                </div>
                <div class="rightValue">
                  <div class="tilName">{{ item.name }}</div>
                  <div class="priceList">
                    <div class="leftPrice">￥{{ item.price }}</div>
                    <div class="chooseBtn">
                        <img
                          src="../assets/shopcart.png"
                          @click="addOneNum(item)"
                          class="cartLogo"
                          v-if="item.count === 0"
                          alt=""
                        />
                        <div class="btnGroup" v-else>
                          <div class="btnSubtraction" @click="subtractNum(item)">
                            <img src="../assets/subtract_disable.png" v-if="item.count <= 1" alt="">
                            <img src="../assets/subtract.png" v-else alt="">
                          </div>
                          <span class="goodsNum">{{ item.count }}</span>
                          <div class="btnPlus" @click="addOneNum(item)">
                            <img src="../assets/plus.png" alt="">
                          </div>
                        </div>
                    </div>
                  </div>
                </div>

                <a class="addr-edit" href="javascript:void(0)"></a>
              </div>
              <div slot="slipbtns" class="slipbtns">
                <a href="javascript:;" @click="deleteGoods(item)">删除</a>
              </div>
            </nut-leftslip>
            <div class="noData" v-show="goodsList.length === 0">购物车是空的</div>
          </div>
        </div>
        <div class="cartBtnGroup">
          <div class="leftChooseAll">
            <el-checkbox v-model="chooseAll" @change="handleCheckAllChange">全选</el-checkbox>
          </div>
            <div class="rightCount">
              <div class="valueCount">合计：<span>{{ totalPrice }}</span></div>
              <div class="conutBtn">
                <el-button
                  type="danger"
                  :disabled="chooseList.length === 0"
                  @click="orderComfirm"
                >结算({{ totalNum }})
                </el-button>
              </div>
            </div>
        </div>
      </el-main>
      <el-footer>
        <footer-part ref="footerPart" />
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import moment from "moment";
import footerPart from "@/components/footer";

export default {
  data() {
    return {
      chooseAll: true, // 全选
      totalPrice: 0,  // 总金额
      totalNum: 0,  // 总数量
      goodsList: [],
      chooseList: [], // 已经选择了的商品
    }
  },
  components: {
    footerPart
  },
  mounted() {
    this.init();
    this.checkChoose();
  },
  methods: {
    init() {
      this.chooseAll = true;
      let getToken = this.$cookies.get("shopToken");
      if (!getToken) {
        sessionStorage.removeItem("valetOrder");
        // this.$cookies.remove("isCarList");
        this.$cookies.remove("shopToken");
      }
      this.getDataList();
    },
    getDataList() {
      let token = this.$cookies.get("shopToken");
      if (token) {
        this.$http.httpGet(`/mobile/onlineShop/getGoodCarList`).then(data => {
          // console.log(data)
          if (data && data.code === 0) {
            if (data.goodList?.length) {
              data.goodList.forEach(i => {
                i.choose = true;
                if (i.imgUrl) {
                  i.imgUrl = process.env.VUE_APP_UPLOAD_URL + i.imgUrl
                }
              })
              this.goodsList = data.goodList;
              this.chooseList = data.goodList;
              this.checkPriceAndTotalNum();
            } else {
              this.goodsList = [];
            }
          } else {
            this.$message.error(data.msg)
          }
        })
      } else {
        this.goodsList = [];
      }
    },
    // 添加商品
    addOneNum(goods) {
      let token = this.$cookies.get("shopToken");
      if (token) {
        this.goodsList.forEach(item => {
          if (item.id === goods.id) {
            item.count += 1;
            this.updataGoodNum(item.id,item.name,item.price,item.count)
            this.checkPriceAndTotalNum();
          }
        })
      } else {
        this.$message.error("请登录后进行操作")
      }
    },
    // 减少商品数量
    subtractNum(goods) {
      let token = this.$cookies.get("shopToken");
      if (token) {
        this.goodsList.forEach(item => {
          // 找到对应的数据
          if (item.id === goods.id) {
            // 判断数据是否只剩下一个，如果是只剩下一个，就要进行删除操作
            if (item.count === 1) {
              let that = this;
              this.$dialog({
                title: "是否确定删除此商品？",
                onOkBtn() {
                  item.count -= 1;
                  that.deleteGoods(goods);
                  that.checkPriceAndTotalNum();
                  that.updataGoodNum(item.id,item.name,item.price,item.count)
                  that.$dialog.closed();
                  // that.$nextTick(() => {
                  //   that.$refs.footerPart.getCarList();
                  // })
                }
              });
            } else {
              item.count -= 1;
              this.updataGoodNum(item.id,item.name,item.price,item.count)
              this.checkPriceAndTotalNum();
              // this.$nextTick(() => {
              //   this.$refs.footerPart.getCarList();
              // })
            }
          }
        })
      } else {
        this.$message.error("请登录后进行操作")
      }
    },

    updataGoodNum(id, name, price, count){
      this.$http.httpPost(`/mobile/onlineShop/updataGoodNum`, {
        id: id,
        name: name,
        price: price,
        count: count,
      }).then(data => {
        if (data && data.code === 0) {
          // console.log(data)
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    // 通用的判断价格和数量
    checkPriceAndTotalNum() {
      this.totalNum = 0;
      this.totalPrice = 0;
      this.chooseList.forEach(item => {
        if (item.choose) {
          this.totalNum += item.count;
          this.totalPrice += parseFloat(item.count * item.price);
        }
      })
    },
    // 删除商品
    deleteGoods(data) {
      let token = this.$cookies.get("shopToken");
      if (token) {
        this.goodsList = this.goodsList.filter(item => (item.id !== data.id));
        this.chooseList = this.chooseList.filter(item => (item.id !== data.id));
        this.checkPriceAndTotalNum();
        this.updataGoodNum(data.id, data.name, data.price, 0);
      } else {
        this.$message.error("请登录后进行操作")
      }
    },
    // 检查购物车勾选情况
    checkChoose() {
      if (this.$route.params.isBack && this.$route.params.isBack === 1) {
        this.chooseList = JSON.parse(sessionStorage.getItem("orderGoods"));
      }
      sessionStorage.removeItem("orderGoods");  // 进入页面后，直接清除session缓存，防止数据串联
      let checkFilter = this.goodsList.filter(item => item.choose === true);
      this.chooseList = checkFilter;
      this.totalNum = 0;
      this.chooseAll = (checkFilter.length === this.goodsList.length);
      this.checkPriceAndTotalNum();
    },
    // 促进判断当前商品选中
    clickOn(data) {
      this.goodsList.forEach(item => {
        if (data.id === item.id) {
          item.choose = !item.choose;
        }
      })
    },
    // 订单确定
    orderComfirm() {
      let orderList = JSON.stringify(this.chooseList);
      sessionStorage.setItem("orderGoods", orderList);
      this.$router.replace({name: "orderComfirm"});
    },
    // 全选和取消全选触发
    handleCheckAllChange(val) {
      this.totalNum = 0;
      if (val) {
        this.goodsList.forEach(item => {
          item.choose = true;
          this.totalNum += item.count;
          this.totalPrice += parseFloat(item.count * item.price);
          this.chooseList.push(item);
        })
      } else {
        this.goodsList.forEach(item => {
          item.choose = false;
          this.totalNum = 0;
          this.totalPrice = 0;
          this.chooseList = [];
        })
      }
    },
    // 判断每一行商品是否勾选
    handleCheckedCitiesChange(data) {
      this.chooseList = []
      this.goodsList.forEach(i => {
        if (i.id === data.id) {
          i.choose = !i.choose
        }
        if (i.choose){
          this.chooseList.push(i);
        }
      })
      this.checkPriceAndTotalNum();
    }
  },
}
</script>

